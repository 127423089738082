<template>
	<div class="qr-table-barrier">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">QR</span>
							</v-btn>
							<!-- Период -->
							<div class="d-flex mr-5">
								<v-select
									v-model="filter.period"
									class="border-all"
									:menu-props="{ offsetY: true }"
									solo
									dense
									flat
									hide-details
									:items="[
										{ text: 'За все время', value: '' },
										{ text: 'День', value: 'day' },
										{ text: 'Неделя', value: 'week' },
										{ text: 'Месяц', value: 'month' },
										{ text: 'Задать период', value: 'period' },
									]"
									@change="updateQuery"
								></v-select>
							</div>
							<!-- Дата создания -->
							<div
								class="d-flex align-center mr-3"
								v-if="filter.period === 'period'"
							>
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.date_first"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.date_second"
									@change="updateQuery"
								/>
								<v-btn class="ml-3" icon>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- <v-btn
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Статус -->
					<td>
						<Select
							v-model="filter.entered"
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Успешно', value: '1' },
								{ text: 'Неуспешно', value: '0' },
							]"
							@change="updateQuery"
						/>
					</td>
					<!-- Телефон -->
					<td>
						<v-text-field
							v-model="filter.phone"
							:placeholder="phoneCode+'-'"
							v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Гос. номер -->
					<td>
						<v-text-field
							v-model="filter.car_number"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Номер въезда -->
					<td>
						<Select
							v-model="filter.entry"
							clearable
							:items="entries"
							item-text="name"
							item-value="_id"
							@change="updateQuery"
						/>
					</td>
					<!-- In -->
					<td>
						<Select
							v-model="filter.in"
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Въезд', value: '1' },
								{ text: 'Выезд', value: '0' },
							]"
							@change="updateQuery"
						/>
					</td>
					<!-- Дата и время -->
					<td>
						<DatePickerPlain
							:date.sync="filter.datetime"
							:in-table="true"
							@change="updateQuery"
						/>
					</td>
					<!-- Паркоместо -->
					<td>
						<v-text-field
							v-model="filter.parking_place"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Блокировка -->
					<td>
						<Select
							v-model="filter.blocked"
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Незаблокированные', value: '0' },
								{ text: 'Заблокированные', value: '1' },
							]"
							@change="updateQuery"
						/>
					</td>
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.entered`]="{ item }">
				<td>
					<span v-if="item.entered" class="green--text">Успешно</span>
					<span v-else class="red--text">Неуспешно</span>
				</td>
			</template>
			<template v-slot:[`item.phone`]="{ item }">
				<td>
					{{ item.user ? item.user.phone : "---" }}
				</td>
			</template>
			<template v-slot:[`item.car_number`]="{ item }">
				<td>
					{{ item.user ? item.user.car_number : "---" }}
				</td>
			</template>
			<template v-slot:[`item.entry`]="{ item }">
				<td>
					{{ item.entry.name || "---" }}
				</td>
			</template>
			<template v-slot:[`item.datetime`]="{ item }">
				<td>
					{{ item.datetime | timestamp(true, { utc: true }) }}
				</td>
			</template>
			<template v-slot:[`item.in`]="{ item }">
				<td>
					<span v-if="item.entry.in">Въезд</span>
					<span v-else>Выезд</span>
				</td>
			</template>
			<template v-slot:[`item.parking_place`]="{ item }">
				<td>
					{{ item.user ? item.user.parking_place : "---" }}
				</td>
			</template>
			<template v-slot:[`item.blocked`]="{ item }">
				<td>
					<span v-if="item.blocked" class="red--text">Заблокирован</span>
					<span v-else>Доступ открыт</span>
				</td>
			</template>
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ QR -->
		<QrTableBarrierModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			@close-modal="showModalCreate = false"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import QrTableBarrierModal from "@/components/QrTableBarrierModal";

export default {
	name: "QrTableBarrier",
	components: {
		DatePickerPlain,
		QrTableBarrierModal,
	},
	watch: {
		options: {
			handler() {
				const query = { ...this.$route.query, page: this.options.page };
				this.$router.replace({ query });
				this.loadData(query);
			},
			deep: true,
		},
		"$route.path"() {
			this.qrType = this.$route.meta.qrType;
			this.options.page = 1
			this.loadData();
			// console.log('this.$route.meta.qrType', this.$route.meta.qrType)
		},
	},
	data() {
		return {
			items: [],
			filter: {
				car_number: this.$route.query.car_number || "",
				datetime: this.$route.query.datetime || "",
				date_first: this.$route.query.date_first || "",
				date_second: this.$route.query.date_second || "",
				entry: this.$route.query.entry_id || "",
				entered: this.$route.query.entered || "",
				in: this.$route.query.in || "",
				parking_place: this.$route.query.parking_place || "",
				page: +this.$route.query.page || 1,
				period: this.$route.query.period || "",
				phone: this.$route.query.phone || "",
				blocked: this.$route.query.blocked || "",
			},
			filtersQuery: {
				"car_number": "car_number",
				"datetime": "datetime",
				"date_first": "date_first",
				"date_second": "date_second",
				"entry": "entry_id",
				"entered": "entered",
				"in": "in",
				"parking_place": "parking_place",
				"page": "page",
				"period": "period",
				"phone": "phone",
				"blocked": "blocked",
			},
			headers: [
				{ text: "Статус", value: "entered", width: "180px", sortable: false },
				{ text: "Телефон", value: "phone", width: "180px", sortable: false },
				{
					text: "Гос. номер",
					value: "car_number",
					width: "150px",
					sortable: false,
				},
				{
					text: "Номер въезда",
					value: "entry",
					width: "250px",
					sortable: false,
				},
				{
					text: "Въезд/Выезд",
					value: "in",
					width: "150px",
					sortable: false,
				},
				{
					text: "Дата и время",
					value: "datetime",
					width: "200px",
					sortable: false,
				},
				{
					text: "Паркоместо",
					value: "parking_place",
					width: "150px",
					sortable: false,
				},
				{
					text: "Блокировка",
					value: "blocked",
					width: "150px",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: true,
			showModalCreate: false,
			qrType: this.$route.meta.qrType,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			entries: "qr/GET_ENTRIES",
			houses: "ksk/GET_HOUSES",
			role: "auth/GET_USER_ROLE_VUEX",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
	},

	methods: {
		timestampToDdmmyyyy,

		// ? Методы таблицы
		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.options.page = 1
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			return this.$api[this.role]
				.load_logs_barrier({ ...params, type: this.qrType })
				.then(res => {
					// this.options.page = res.page;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
					console.log(this.items);
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});
			this.options.page = 1
			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses");
		this.$store.dispatch("qr/load_entries");
		console.log('CREATED')
	},

	async mounted() {
		try {
			const query = { ...this.$route.query };
			await this.loadData(query);
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped>
.qr-table-barrier {
	height: 100vh;
}
</style>
